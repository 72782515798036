$image-size-width: 20rem;
$image-size-height: 5rem;

.image-preview {
  width: $image-size-width;
  height: $image-size-height;
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-md);
  background-color: var(--color-white);
  padding: var(--spacing-xs);

  &__content {
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    background-color: var(--color-grey-light);
    border-radius: var(--border-radius-sm);

    &__icon {
      font-size: $font-size-36;
      color: var(--color-grey);
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      user-select: none;
    }
  }
}
