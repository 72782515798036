.element-list {
  & &__tag-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-radius: var(--border-radius-xl);
    border: 1px solid var(--color-grey);
    background-color: var(--color-grey-light);
    color: var(--color-midnight-light);
    gap: var(--spacing-xs);
    padding: var(--spacing-xs);

    &--highlighted {
      background-color: var(--color-midnight-light);
      color: var(--color-grey-light);
    }

    &__infos {
      display: inline-flex;
      align-items: center;
      text-align: left;
      gap: var(--spacing-xs);
    }
  }
}
