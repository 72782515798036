.tag-taxonomy-count {
  display: flex;

  & &__name {
    padding-right: 2rem;
  }

  & &__count {
    left: -1.5rem;
    margin-right: -1.5rem;
  }
}
