.value-copier {
  display: flex;
  align-items: center;
  gap: var(--spacing-xs);

  &__value {
    font-size: $font-size-14;
    font-weight: $font-weight-semi-bold;
    color: var(--color-ocean);
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    color: var(--color-midnight-light);

    i {
      font-size: $font-size-18;
    }
  }
}
