.modules {
  & h2 {
    font-size: $font-size-18;
    font-weight: $font-weight-bold;
    letter-spacing: 0.2pt;
    color: var(--color-midnight-light);
    text-transform: uppercase;
  }

  & &__content {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
    grid-gap: var(--spacing-sm);
  }

  & &__group {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
    text-align: left;

    &__title {
      display: inline-flex;
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      color: var(--color-green);
      gap: var(--spacing-xs);
    }

    ul.modules__group__items {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);

      li {
        font-size: $font-size-14;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight-light);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
