.iframe-block {
  position: relative;
  display: block;
  width: 100%;
  padding-bottom: 56%;
  border-radius: inherit;

  iframe {
    position: absolute !important;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    margin: auto;
    border: 0;
    border-radius: inherit;
  }
}
