.media-cards {
  display: grid;
  grid-gap: var(--spacing-sm);
  grid-template-columns: repeat(auto-fill, minmax(16rem, 1fr));

  @media #{$screen-s} {
    grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr));
  }
}

.media-box {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-lighter);
  color: var(--color-grey-light);
  width: 100%;
  height: 25rem;
  overflow: hidden;

  > * {
    max-width: 100%;
    max-height: 100%;
  }

  &__icon {
    font-size: 15rem;
  }
}

.card-media {
  position: relative;
  height: 13rem;
  overflow: hidden;
  cursor: pointer;

  &__menu {
    display: inline-flex;
    position: absolute;
    top: 1rem;
    right: 1rem;
    z-index: 1;
    color: var(--color-black-light);
    background-color: var(--color-grey-light);
    padding: .125rem;
    border-radius: var(--border-radius-xl);

    i {
      font-size: $font-size-20;
    }
  }

  &__content {
    width: 100%;
    height: 100%;

    > * {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &__icon {
      font-size: 7rem;
      color: var(--color-grey-light);
      background-color: var(--color-black-lighter);
      padding-bottom: 2.75rem;
    }
  }

  &__name {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: var(--color-black-light);
    opacity: 0.7;
    padding: 0.5rem 2rem;
    display: block;
    text-align: center;
    white-space: nowrap;
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: $font-size-14;
    font-weight: $font-weight-bold;
    color: var(--color-grey-light);
  }

  &:hover {
    &:before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: var(--color-grey-light);
      opacity: .5;
    }
  }
}
