.networks {
  .network-item {
    position: relative;

    &__img {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 5rem;
      max-width: 100%;

      img, svg {
        max-height: 100%;
        max-width: 100%;
        user-select: none;
        cursor: pointer;
      }
    }

    &__info {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      line-height: 1.5;

      &__name {
        margin-bottom: var(--spacing-sm);
        font-size: $font-size-18;
        font-weight: $font-weight-bold;
      }
    }
  }
}
