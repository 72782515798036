.dataleaks {
  &__question {
    &:not(:last-child) {
      padding-bottom: var(--spacing-lg);
      border-bottom: 1px solid var(--color-grey);
    }

    &__chip {
      display: inline-flex;
      align-items: center;
      justify-content: center;
      padding: 0 var(--spacing-xs);
      margin-right: var(--spacing-sm);
      width: 2rem;
      height: 2rem;
      background-color: var(--color-grey-light);
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-xs);
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      color: var(--color-ocean);
    }

    &__title {
      color: var(--color-ocean);
    }

    &__flag {
      margin: 0 var(--spacing-xs);
    }

    &__link {
      margin: var(--spacing-xs) 0;
      font-size: $font-size-12;
    }

    &__buttons {
      page-break-after: avoid;

      min-width: 10rem;

      &__item {
        width: 100%;
        color: var(--color-midnight-light);

        & > input {
          display: none;

          &:checked + .dataleaks__question__buttons__item__label {
            color: var(--color-ocean);
            border-color: var(--color-ocean);
            box-shadow: 0 1px var(--color-ocean);
          }
        }

        &__label {
          display: flex;
          justify-content: center;
          border: 1px solid var(--color-grey);
          border-radius: var(--border-radius-xs);
          background-color: var(--color-grey-light);
          padding: var(--spacing-xs) var(--spacing-sm);

          &:hover,
          &:active {
            color: var(--color-ocean);
            border-color: var(--color-ocean);
            box-shadow: inset 1px var(--color-ocean);
          }
        }
      }
    }

    &__result {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-sm);
      width: 10rem;
      border-radius: var(--border-radius-xs);
      padding: var(--spacing-sm);

      &--critical {
        color: var(--color-danger-main);
        border: 1px solid var(--color-danger-main);
        box-shadow: 0 3px var(--color-danger-main);
      }

      &--major {
        color: var(--color-warning-main);
        border: 1px solid var(--color-warning-main);
        box-shadow: 0 3px var(--color-warning-main);
      }

      &--minor {
        color: var(--color-yellow);
        border: 1px solid var(--color-yellow);
        box-shadow: 0 3px var(--color-yellow);
      }

      &--success {
        color: var(--color-success-main);
        border: 1px solid var(--color-success-main);
        box-shadow: 0 3px var(--color-success-main);
      }

      &__icon {
        i {
          font-size: $font-size-28;
        }
      }

      &__label {
        font-size: $font-size-12;
      }
    }
  }

  &__scoring {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-sm);

    &__item {
      display: flex;
      flex: 1 1 45%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-xs);
      padding: var(--spacing-sm);
      border-radius: var(--border-radius-sm);
      border: 1px solid var(--color-grey);
      background: var(--color-white);
      text-align: center;

      &__score {
        color: var(--color-midnight-light);

        &__big-value {
          font-size: $font-size-48;
          font-weight: $font-weight-bold;
        }

        &__small-value {
          font-size: $font-size-22;
          font-weight: $font-weight-semi-bold;
        }
      }
    }

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
      flex-wrap: wrap;
    }
  }

  &__severities {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-sm);

    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: var(--spacing-xs);

      &--total {
        padding-left: var(--spacing-sm);
        border-left: 1px solid var(--color-grey);
      }

      &__label {
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight-light);
      }
    }
  }

  &__flag {
    &--critical {
      color: var(--color-danger-main);
    }

    &--major {
      color: var(--color-warning-main);
    }

    &--minor {
      color: var(--color-yellow);
    }
  }
}
