.preview {
  height: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid var(--color-grey);
  border-radius: var(--border-radius-md);
  overflow: hidden;

  &__actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm);
    background-color: var(--color-grey-light);
    border-bottom: 1px solid var(--color-grey);
  }

  &__toggle {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    user-select: none;

    &__button {
      display: flex;
      padding: var(--spacing-xs) var(--spacing-sm);
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      color: var(--color-midnight);

      &--active {
        border-color: var(--color-ocean);
        background-color: var(--color-ocean-lighter);
        color: var(--color-ocean);
        box-shadow: inset 0 -1px var(--color-ocean);
      }
    }

    > :first-child {
      .preview__toggle__button {
        border-top-left-radius: var(--border-radius-sm);
        border-bottom-left-radius: var(--border-radius-sm);
      }
    }

    > :last-child {
      .preview__toggle__button {
        border-top-right-radius: var(--border-radius-sm);
        border-bottom-right-radius: var(--border-radius-sm);
      }
    }

    &__label {
      font-size: $font-size-16;
      font-weight: $font-weight-semi-bold;
    }

    & i:hover {
      color: inherit;
    }
  }

  &__container {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    &__iframe {
      position: relative;
      height: auto;
      padding: 0 0 75% 0;
      overflow: hidden;

      iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        &.iframe--hidden {
          display: none;
        }
      }
    }

    &--mobile {
      max-width: 25rem;
      padding: var(--spacing-sm);

      .preview__container__iframe {
        padding: 0 0 175% 0;
        border: 1px solid var(--color-grey);
        box-shadow: $box-shadow;
      }
    }
  }
}
