.taxonomy-path {
  display: flex;
  align-items: center;
  gap: calc(var(--spacing-xs)) 0;
  flex-wrap: wrap;

  > span {
    display: flex;
  }

  i {
    font-size: $font-size-20;
  }
}
