.theme-position {
  &__preview {
    display: flex;
    justify-content: center;
    padding: var(--spacing-sm);
    background-color: var(--color-grey);
    border-radius: var(--border-radius-md);
    width: 50%;

    img {
      width: 100%;
    }
  }

  &__options {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xs);
  }
}

.theme-colors {
  & &__mode {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    &__icon {
      padding: .4rem;
      border-radius: 50%;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      color: var(--color-midnight);
      font-size: $font-size-14;

      &--dark {
        background-color: var(--color-midnight);
        color: var(--color-grey);
      }
    }
  }
}

.theme-images {
  & &__mode {
    display: flex;
    align-items: flex-start;
    position: relative;
    margin-top: var(--spacing-xs);
    margin-left: var(--spacing-xs);

    &__icon {
      position: absolute;
      top: calc(-1 * var(--spacing-xs));
      left: calc(-1 * var(--spacing-xs));
      z-index: 1;
      padding: .4rem;
      border-radius: 50%;
      border: 1px solid var(--color-grey);
      background-color: var(--color-white);
      color: var(--color-midnight);
      font-size: $font-size-14;

      &--dark {
        background-color: var(--color-midnight);
        color: var(--color-grey);
      }
    }
  }
}

.cmp-add-ons {
  &__item {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-xl);
    width: calc(20% - var(--spacing-sm));
    min-width: 20rem;
    min-height: 15rem;

    &__header {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      gap: var(--spacing-sm);
    }

    &__image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 5rem;
      height: 3rem;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__fields {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);
      width: 100%;
    }
  }
}

.cmp-networks {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-md);
    border-width: 2px;

    &:hover {
      box-shadow: none;
    }

    &--active {
      border: 2px solid var(--color-green);
    }

    &__image-container {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 var(--spacing-sm);
      max-width: 100%;
      width: 14rem;
      height: 3rem;

      svg, img {
        max-height: 100%;
        max-width: 100%;
      }
    }

    &__name {
      font-weight: $font-weight-semi-bold;
    }
  }
}

.cmp-languages {
  &__item {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    min-width: 5rem;
    padding: var(--spacing-sm);
    background-color: var(--color-grey-light);
    border: 1px solid var(--color-grey);
    cursor: pointer;
    user-select: none;

    &:first-child {
      border-top-left-radius: var(--border-radius-md);
      border-bottom-left-radius: var(--border-radius-md);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius-md);
      border-bottom-right-radius: var(--border-radius-md);
    }

    &:not(:last-child) {
      border-right: 0;
    }

    &:hover {
      background-color: var(--color-grey);
      box-shadow: inset 0 -1px var(--color-grey-dark);
    }

    &--active {
      border: 1px solid var(--color-ocean) !important;
      background-color: var(--color-ocean-lighter) !important;
      box-shadow: inset 0 -1px var(--color-ocean) !important;
    }

    &__image-container {
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-xs);
      height: 1.75rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }
    }

    &__name {
      display: flex;
      align-items: center;
      text-align: center;
      margin-top: var(--spacing-xs);
      font-size: $font-size-12;
      font-weight: $font-weight-semi-bold;
      color: var(--color-midnight-light);
      height: 2.25rem;

      &--highlighted {
        font-weight: $font-weight-bold;
        color: var(--color-midnight-dark);
      }
    }
  }
}

.purposes-display-section {
  & &__item {
    input[type=radio] {
      display: none;
    }

    input[type=radio]:checked + label {
      border-color: var(--color-green);
      box-shadow: inset 0 -1px var(--color-green);
    }

    label {
      display: flex;
      padding: var(--spacing-lg);
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-md);

      @media #{$screen-s}, #{$screen-m} {
        padding: var(--spacing-md);
      }

      img {
        max-width: 100%;
      }
    }

    &__title {
      color: var(--color-midnight-light);
      font-weight: $font-weight-semi-bold;
    }
  }
}
