.purposes-checkboxes {
  &__item {
    color: var(--color-midnight-light);

    > input[type="checkbox"] {
      display: none;

      &:checked + .purposes-checkboxes__item__label {
        color: var(--color-ocean);
        border-color: var(--color-ocean);
        box-shadow: 0 1px var(--color-ocean);
      }

      &:disabled + .purposes-checkboxes__item__label {
        border-color: var(--color-grey);
        color: var(--color-grey);
        background-color: var(--color-grey-light);
      }
    }

    &__label {
      cursor: pointer;
      display: flex;
      justify-content: center;
      border: 1px solid var(--color-grey);
      border-radius: var(--border-radius-xs);
      background-color: var(--color-grey-light);
      padding: var(--spacing-xs) var(--spacing-sm);
      width: 3rem;
      font-weight: $font-weight-semi-bold;

      &:hover,
      &:active {
        color: var(--color-ocean);
        border-color: var(--color-ocean);
        box-shadow: inset 1px var(--color-ocean);
      }
    }
  }
}

.sirdata-list {
  &__translations {
    display: flex;
    gap: var(--spacing-lg);

    &__item {
      &:not(:last-child) {
        padding-bottom: var(--spacing-lg);
        border-bottom: 1px solid var(--color-grey);
      }

      &__chip {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0 var(--spacing-xs);
        margin-right: var(--spacing-sm);
        width: 2rem;
        height: 2rem;
        background-color: var(--color-grey-light);
        border: 1px solid var(--color-grey);
        border-radius: var(--border-radius-xs);
        font-size: $font-size-18;
        font-weight: $font-weight-bold;
        color: var(--color-ocean);
      }

      &__form {
        width: 100%;
      }
    }
  }
}
