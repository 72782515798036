.print-viewer {
  position: relative;
  background-color: var(--color-black-lighter);
  padding: var(--spacing-sm);
  min-height: 100vh;

  > * {
    @media screen {
      width: 60rem;
      margin: auto;
      padding: var(--spacing-xl);
      background-color: var(--color-white);
      box-shadow: 0 0 10px var(--color-black-light);
      zoom: 110%;
    }
  }

  * {
    print-color-adjust: exact;
  }
}
