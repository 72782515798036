.categories {
  &__types {
    display: flex;
    justify-content: space-between;
    gap: var(--spacing-lg);

    &__item {
      display: flex;
      flex: 1 1 45%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: var(--spacing-md);
      padding: var(--spacing-sm);
      border-radius: var(--border-radius-md);
      border: 2px solid var(--color-grey);
      background: var(--color-white);
      color: var(--color-grey-dark);
      text-align: center;
      cursor: pointer;

      &__name {
        display: flex;
        align-items: center;
        gap: var(--spacing-sm);
        font-size: $font-size-16;
        font-weight: $font-weight-bold;
        text-transform: uppercase;
      }

      &__icon {
        font-size: $font-size-34;
      }

      &--active {
        box-shadow: inset 0 0 8px var(--color-ocean);
        border: 2px solid var(--color-ocean);
        color: var(--color-midnight-light);
      }

      &:hover,
      &--selected {
        box-shadow: inset 0 0 8px var(--color-green);
        border: 2px solid var(--color-green);
      }
    }

    @media #{$screen-s}, #{$screen-m}, #{$screen-l} {
      flex-wrap: wrap;
    }
  }

  &__sourcing {
    display: flex;
    flex-direction: column;
    gap: calc(var(--spacing-md) * 2);
  }
}
