.changelog {
  & &__content {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);
  }

  & &__item {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: var(--spacing-sm);
    color: var(--color-midnight-light);

    &:not(:last-child) {
      padding-bottom: var(--spacing-md);
      border-bottom: 1px solid var(--color-grey);
    }

    &__date {
      font-size: $font-size-16;
      font-weight: $font-weight-bold;
    }

    &__content {
      li {
        display: flex;
        align-items: flex-start;
        font-size: $font-size-14;
        line-height: 1.5;
        margin-bottom: var(--spacing-xs);

        &:before {
          content: "\ef4a";
          font-variation-settings: "FILL" 1, "wght" 400, "GRAD" 0, "opsz" 24;
          font-family: $font-family-icon;
          font-size: $font-size-12;
          margin-right: var(--spacing-xs);
          color: var(--color-green);
          line-height: 1.7;
        }
      }
    }
  }
}
