.main-content-header {
  position: sticky;
  top: var(--main-header-height);
  left: 0;
  right: 0;
  z-index: 19;

  & &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: var(--spacing-xs);
    padding: var(--spacing-sm) var(--spacing-xl);
    background-color: var(--color-grey-light);

    @media #{$screen-s}, #{$screen-m} {
      padding: var(--spacing-sm) var(--spacing-lg);
    }
  }

  & &__breadcrumb {
    display: flex;
    align-items: center;
    gap: var(--spacing-xs);

    .breadcrumb__item {
      display: inline-flex;
      align-items: center;
      gap: var(--spacing-xs);
      font-size: $font-size-16;
      font-weight: $font-weight-semi-bold;
      color: var(--color-midnight);

      &:not(:last-child) {
        &:after {
          content: "\e315";
          font-family: $font-family-icon;
          font-size: $font-size-14;
          color: var(--color-grey-dark);
        }
      }

      &__icon {
        display: flex;
        align-items: center;
        background-color: var(--color-white);
        color: var(--color-midnight);
        font-size: $font-size-20;
      }

      &__link {
        color: var(--color-midnight-lighter);
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }

      &__action i {
        font-size: $font-size-16;
      }
    }
  }

  & &__tools {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    gap: var(--spacing-sm);

    .tools__infos {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);

      > :not(:last-child) {
        padding-right: var(--spacing-xs);
        border-right: 1px solid var(--color-grey);
      }

      &__item {
        display: inline-flex;
        align-items: center;
        gap: calc(var(--spacing-xs) / 2);
        font-size: $font-size-12;
        color: var(--color-midnight-lighter);
        white-space: nowrap;

        i {
          font-size: $font-size-16;
        }

        &__bullet {
          display: block;
          width: $font-size-14;
          height: $font-size-14;
          margin: 0 0.0925rem;
          border-radius: 50%;
        }
      }
    }

    .tools__actions {
      display: flex;
      align-items: center;
      gap: var(--spacing-xs);

      &__button {
        padding: var(--spacing-xs);
        border-radius: var(--border-radius-xs);
        border: 1px solid var(--color-grey);
        cursor: pointer;

        &--actions-menu {
          background-color: var(--color-white);
          color: var(--color-midnight-light);

          &:hover {
            background-color: var(--color-midnight-light);

            i {
              color: var(--color-white);
            }
          }
        }

        &--critical {
          color: var(--color-danger-main);
        }

        i {
          font-size: $font-size-20;
          cursor: pointer;
        }
      }
    }
  }
}
