.search-items {
  position: relative;

  &__results {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: var(--spacing-xs);
    background-color: var(--color-white);
    overflow: hidden;
    z-index: 1;
    box-shadow: $box-shadow;
    user-select: none;

    &__header {
      height: 3.5rem;
      padding: 0 var(--spacing-sm);
      background-color: var(--color-grey-light);
      border-bottom: 1px solid var(--color-grey);
      font-weight: $font-weight-bold;

      &__label {
        display: flex;
        align-items: center;
        gap: calc(var(--spacing-xs) / 2);
      }

      &__icon {
        font-size: $font-size-18 !important;
        color: var(--color-red);
      }
    }

    &__list {
      max-height: 20rem;
      overflow: auto;

      &__item {
        padding: var(--spacing-xs) var(--spacing-sm);
        cursor: pointer;

        &:hover {
          background-color: var(--color-grey-light);
        }

        &__checkbox {
          display: flex;
          align-items: center;
          height: 2rem;
        }
      }
    }
  }
}
