.score-circle-container {
  --score-circle-size: 15rem;
  --score-circle-track-width: 1.5rem;
  --score-circle-label-font-size: #{$font-size-16};
  --score-circle-value-font-size: #{$font-size-36};

  &--small {
    --score-circle-size: 10rem;
    --score-circle-track-width: 1rem;
    --score-circle-label-font-size: #{$font-size-14};
    --score-circle-value-font-size: #{$font-size-22};
  }

  .score-circle {
    *, *:before, *:after {
      box-sizing: content-box;
    }

    position: relative;
    width: var(--score-circle-size);
    height: var(--score-circle-size);
    border-radius: 50%;
    box-shadow: 0 0 0 var(--score-circle-track-width) inset var(--color-grey);

    &:after {
      position: absolute;
      top: var(--score-circle-track-width);
      left: var(--score-circle-track-width);
      display: block;
      content: " ";
      border-radius: 50%;
      width: calc(var(--score-circle-size) - (2 * var(--score-circle-track-width)));
      height: calc(var(--score-circle-size) - (2 * var(--score-circle-track-width)));
      background-color: var(--color-white);
    }

    .score-circle-value {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%, 0);
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: var(--spacing-xs);
      z-index: 1;

      .score-circle-label {
        font-size: var(--score-circle-label-font-size);
        font-weight: $font-weight-bold;
        color: var(--color-ocean);
      }

      .score-circle-number {
        font-size: var(--score-circle-value-font-size);
        font-weight: $font-weight-bold;
      }
    }

    .score-circle-track {
      --score-circle-half-size : calc(var(--score-circle-size) / 2);

      position: absolute;
      width: var(--score-circle-size);
      height: var(--score-circle-size);
      clip: rect(0rem, var(--score-circle-size), var(--score-circle-size), var(--score-circle-half-size));
      border-color: var(--color-grey);

      .score-circle-trackbar {
        position: absolute;
        border-width: var(--score-circle-track-width);
        border-style: solid;
        border-color: inherit;
        width: calc(var(--score-circle-size) - (2 * var(--score-circle-track-width)));
        height: calc(var(--score-circle-size) - (2 * var(--score-circle-track-width)));
        clip: rect(0, var(--score-circle-half-size), var(--score-circle-size), 0);
        border-radius: 50%;
        transform: rotate(0deg);
      }

      .score-circle-run {
        @extend .score-circle-trackbar;
      }
    }

    @for $j from 51 through 100 {
      &.p#{$j} {
        .score-circle-track {
          clip: rect(auto, auto, auto, auto);

          .score-circle-run:after {
            transform: rotate(180deg);
          }

          .score-circle-fixed {
            @extend .score-circle-trackbar;
            transform: rotate(180deg);
          }
        }
      }
    }

    @for $j from 1 through 100 {
      &.p#{$j} {
        .score-circle-track {
          .score-circle-run {
            transform: rotate((calc(360/100)*$j) + deg);
          }
        }
      }
    }
  }
}
