.dataleaks__report {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-lg);

  &__warning {
    text-align: center;
    font-style: italic;
    font-size: $font-size-10;
    color: var(--color-black-lighter);
    margin: var(--spacing-xs) 0;
  }

  &__section {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-md);

    &__title {
      font-size: $font-size-18;
      font-weight: $font-weight-bold;
      break-after: avoid-page;
      color: var(--color-midnight);
    }
  }

  &__logo {
    svg {
      height: 4rem;
    }
  }

  &__infos {
    border-color: var(--color-ocean);
    box-shadow: inset 0 -1px var(--color-ocean);
    width: 50%;

    &__item {
      display: flex;
      flex-direction: row;
      gap: var(--spacing-md);

      &__name {
        color: var(--color-ocean);
        font-size: $font-size-12;
        width: 5rem;
      }

      &__value {
        font-size: $font-size-12;
        color: var(--color-midnight-light);
      }
    }

    &__logo {
      display: flex;
      justify-content: center;

      img {
        max-height: 5rem;
        max-width: 15rem;
      }
    }
  }

  &__scores {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);

    &__title {
      text-align: center;
      font-size: $font-size-14;
      font-weight: $font-weight-bold;
      color: var(--color-midnight-light);
    }

    &__item {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);

      &__title {
        display: inline-block;
        text-align: center;
        font-size: $font-size-12;
        font-weight: $font-weight-semi-bold;
        color: var(--color-midnight-light);
        break-after: avoid-page;
      }
    }
  }

  &__website-info {
    display: flex;
    flex-direction: row;
    gap: var(--spacing-md);

    &__name {
      color: var(--color-ocean);
      font-size: $font-size-12;
    }

    &__value {
      font-size: $font-size-12;
      color: var(--color-midnight-light);
    }
  }

  &__questions {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);
  }

  &__question {
    &:not(:last-child) {
      padding-bottom: var(--spacing-sm);
      border-bottom: 1px solid var(--color-grey);
    }

    &__title {
      color: var(--color-ocean);
      font-size: $font-size-12;
      break-after: avoid-page;
    }

    &__tag {
      color: var(--color-grey-dark);
      font-size: $font-size-10;
      font-weight: $font-weight-semi-bold;
      font-style: italic;
    }

    &__answer {
      display: flex;
      align-items: center;
      gap: calc(var(--spacing-xs) / 2);

      &--failure {
        color: var(--color-danger-main);
      }

      &--success {
        color: var(--color-success-main);
      }

      &__icon {
        i {
          font-size: $font-size-28;
        }
      }

      &__label {
        font-size: $font-size-10;
        font-weight: 900;
        text-transform: uppercase;
      }
    }

    &__text {
      font-size: $font-size-10;
      color: var(--color-midnight-light);
      text-align: justify;
    }
  }

  &__results {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);

    &__description {
      font-size: $font-size-10;
      color: var(--color-midnight-light);
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);

      &__title {
        display: inline-flex;
        gap: var(--spacing-xs);
        color: var(--color-ocean);
        font-size: $font-size-12;
        break-after: avoid-page;

        &__icon {
          font-size: $font-size-16;
        }
      }

      ul {
        li {
          list-style: disc;
          list-style-position: inside;
          color: var(--color-midnight-light);
          font-size: $font-size-10;
          line-height: 1.5;
        }
      }
    }
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-sm);

    &__title {
      text-align: center;
      font-size: $font-size-14;
      font-weight: $font-weight-bold;
      color: var(--color-midnight-light);
    }

    &__section {
      display: flex;
      flex-direction: column;
      gap: var(--spacing-xs);

      &__title {
        display: inline-flex;
        gap: var(--spacing-xs);
        color: var(--color-ocean);
        font-size: $font-size-12;
        break-after: avoid-page;
      }

      &__text {
        font-size: $font-size-10;
        color: var(--color-midnight-light);
      }

      ul {
        li {
          list-style: disc;
          list-style-position: inside;
          color: var(--color-midnight-light);
          font-size: $font-size-10;
          line-height: 1.5;
        }
      }
    }
  }
}
