.media-picker {
  height: 13rem;
  cursor: pointer;
  flex: auto!important;

  > * {
    position: relative;
    width: 100%;
    height: 100%;
  }

  &__media {
    background-color: var(--color-black-lighter);

    &__content {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      border-radius: inherit;
      overflow: hidden;

      > * {
        max-width: 100%;
        max-height: 100%;
      }
    }

    &__content {
      &__icon {
        font-size: 10rem;
        color: var(--color-grey);
      }

      &__name {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: var(--color-black-light);
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: inherit;
        opacity: 0.7;
        padding: 0.5rem 2rem;
        display: block;
        text-align: center;
        white-space: nowrap;
        word-break: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: $font-size-14;
        font-weight: $font-weight-bold;
        color: var(--color-grey-light);
      }
    }

    &__button {
      display: inline-flex;
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
      color: var(--color-midnight);
      background-color: var(--color-white);
      border-radius: 50%;

      &:hover {
        color: var(--color-green);
      }

      i {
        font-size: $font-size-20;
      }
    }
  }

  &__placeholder {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: $font-size-20;

    &:hover {
      color: var(--color-green);
    }
  }
}
